"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAOhost = exports.AO_HOST = void 0;
var queryParser_1 = require("../utils/queryParser");
var asset = (0, queryParser_1.default)().asset;
exports.AO_HOST = asset;
var setAOhost = function (val) {
    var currentProtocol = window.location.protocol;
    var assetProtocol = val.split(':')[0] + ':';
    if (currentProtocol === 'https:' && assetProtocol === 'http:') {
        // In this specific case, we need to request assets over HTTPS
        console.log('Upgrading asset link to https');
        val = val.replace('http:', 'https:');
    }
    exports.AO_HOST = val;
};
exports.setAOhost = setAOhost;
