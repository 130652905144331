"use strict";
/* eslint @typescript-eslint/no-explicit-any: "warn" */
Object.defineProperty(exports, "__esModule", { value: true });
var queryParser = function () {
    var protocol = window.location.protocol;
    var urlParams = new URLSearchParams(window.location.search);
    var queryParams = {
        ip: urlParams.get("ip") || "",
        connect: urlParams.get("connect") || "",
        mode: urlParams.get("mode") || "join",
        asset: urlParams.get("asset") || "".concat(protocol, "//attorneyoffline.de/base/"),
        theme: urlParams.get("theme") || "default",
        serverName: urlParams.get("serverName") || "Attorney Online session",
    };
    return queryParams;
};
exports.default = queryParser;
